.wrapper {
    max-width: 1240px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 120px;
}

.container {
    max-width: 930px;
}

.dot {
    padding-left: 24px;
    position: relative;
}

.dot:before {
    content: '';
    display: inline-block;
    background: #213140;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: 7px;
}

.table {
    background: #fff;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    border-collapse: collapse;

    font-size: 16px;
    font-weight: 400;
    font-family: 'HelveticaNeueCyr-Medium', sans-serif;
    margin-top: 20px;
}

.table tr td {
    border: 1px solid #dddddd;
    padding: 5px;
}